import { useEffect } from 'react';

const useWkTooltipOutsideComponent = (id: string) => {
  const moveWkTooltipToOutsideElement = () => {
    const wkTooltip = Array.from(document.querySelectorAll(`#${id}`));
    const fixedTooltips = document.querySelectorAll(`#${id}-temp`);
    if (wkTooltip.length > 0 && fixedTooltips.length === 0) {
      const tooltipElement = wkTooltip[0] as HTMLElement;
      tooltipElement.style.display = 'none';
      tooltipElement.id = `${id}-temp`;
      tooltipElement.style.display = 'block';
      tooltipElement.style.position = 'fixed';
      document.body.appendChild(tooltipElement);
    }
  };

  useEffect(() => {
    return () => {
      document
        .querySelectorAll(`#${id}-temp`)
        .forEach((element) => element.remove());
    };
  }, [id]);
  return { moveWkTooltipToOutsideElement };
};

export default useWkTooltipOutsideComponent;
