import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IOrchestrationsServerResponse } from 'interfaces/dashboard/dashboard-slice.interface';
import { ILogFromServer } from 'interfaces/log-row.interface';
import { IOrchestrationServer } from 'interfaces/orchestration.interface';
import { IPostJobsInfo } from 'interfaces/post-jobs-info.interface';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { uuidSignal } from 'utils/hooks/useSSE';

const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred';
const BASE_URL = '/csb/jobs';

export const fetchAllOrchestrations = createAsyncThunk(
  'dashboardContainer/fetchAllOrchestrations',
  async (data: IPostJobsInfo, { rejectWithValue }) => {
    try {
      const response = await axios.post<IOrchestrationsServerResponse>(
        `${BASE_URL}/sse/${uuidSignal}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);

export const getOrchestrationById = createAsyncThunk(
  'dashboardContainer/getOrchestrationById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IOrchestrationServer>(
        `${BASE_URL}/${id}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);

export const postViewLog = createAsyncThunk(
  'dashboardContainer/postViewLog',
  async (data: Object, { rejectWithValue }) => {
    try {
      const response = await axios.post<ILogFromServer[]>(
        `${BASE_URL}/log`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);
