import { createRef, useEffect, useState } from 'react';
import style from './LocalFileUpload.module.scss';
import {
  DEFAULT_UPLOAD_STATUS_I18N,
  DragAndDrop,
  Iconsvg,
  UploadStatus,
  UploadStatusI18n,
} from '@wk/components-react16';
import React from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

export interface ILocalFileUpload {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
}

const LocalFileUpload = ({ register, setValue }: ILocalFileUpload) => {
  const storyContainer = createRef<any>();
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    setValue('content', [...files.map((file) => file.nativeFile)]);
  }, [files]);

  useEffect(() => {
    register('content');
  }, []);

  function handleFilesChange(event: any) {
    const targetFiles = Array.isArray(event)
      ? event
      : Array.from(event.target.files);
    const newFileItems = targetFiles.map((file: File, index: number) => {
      const uiFile = {
        id: _nextId(index),
        nativeFile: file,
        label: file.name,
        type: file.type,
        progress: 0,
      };
      return {
        ...uiFile,
        status: 'completed',
      };
    });
    setFiles([...newFileItems, ...files]);
  }

  function handleFileItemClick(fileItem: any) {
    if (fileItem.status !== 'uploading') {
      setFiles((fileItems: any) => [
        ...fileItems.filter((f: any) => f.id !== fileItem.id),
      ]);
      const nativeInput = storyContainer.current.ownerDocument.querySelector(
        'input[type="file"]'
      ) as HTMLInputElement;
      nativeInput.value = '';
    }
  }

  return (
    <div
      className={style['tab-drag-n-drop']}
      data-testid="UploadFilesFirstStep"
      ref={storyContainer}
    >
      <DragAndDrop onFilesChange={(event: any) => handleFilesChange(event)}>
        <input
          id="UploadInput"
          type="file"
          name="file-upload"
          data-testid="UploadInput"
          multiple
          onChange={(event: any) => handleFilesChange(event)}
          slot="dragAndDropInput"
        />
        {Object.values(files).map((fileItem: any, i) => {
          return (
            <React.Fragment key={'key-' + i.toString()}>
              <UploadStatus
                label={fileItem.label}
                status={fileItem.status}
                size={fileItem.nativeFile.size}
                i18n={getI18n(fileItem.error)}
                progress={fileItem.progress}
                isCompact
                onItemClick={() => handleFileItemClick(fileItem)}
                slot="dragAndDropListItem"
              >
                <Iconsvg name={getIconName(fileItem)}></Iconsvg>
              </UploadStatus>
            </React.Fragment>
          );
        })}
      </DragAndDrop>
    </div>
  );
};

function _nextId(id: number): string {
  return 'i-' + Math.random();
}

function formatBytes(targetSize: number): {
  value: number;
  specifier: string;
} {
  const sizeSpecifiers: string[] = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ];
  let specifierIndex = 0,
    number = targetSize;
  while (number >= 1024) {
    number = number / 1024;
    ++specifierIndex;
  }

  return {
    value: +number.toFixed(number < 10 && specifierIndex > 0 ? 1 : 0),
    specifier: sizeSpecifiers[specifierIndex],
  };
}

function uploadingStatusText(uploadedSize: number, totalSize: number) {
  const { value: totalFileSize, specifier: totalFileSizeSpecifier } =
    formatBytes(totalSize);
  const { value: uploadedFileSize, specifier: uploadedFileSizeSpecifier } =
    formatBytes(uploadedSize);
  return `Uploaded ${uploadedFileSize} ${uploadedFileSizeSpecifier} of ${totalFileSize} ${totalFileSizeSpecifier}`;
}

function getI18n(error: { errorMessage?: string }): UploadStatusI18n {
  return {
    ...DEFAULT_UPLOAD_STATUS_I18N,
    errorMessage: error?.errorMessage,
    uploadingStatusText,
  };
}

function getIconName(file: any) {
  if (file.type.includes('pdf')) {
    return 'file-pdf';
  } else if (file.type.includes('jpeg') || file.type.includes('png')) {
    return 'photo';
  } else {
    return 'file';
  }
}

export default LocalFileUpload;
