import {
  ButtonField,
  CheckboxField,
  Iconsvg,
  InputField,
} from '@wk/components-react16';
import { useLazyGetS3FilesQuery } from 'api/orchestrations/orchestrationApi';
import { useEffect, useState } from 'react';
import style from './S3FileUpload.module.scss';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types';

export interface IS3FileUpload {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
}
const S3FileUpload = ({ register, setValue }: IS3FileUpload) => {
  const [fetchS3Files, { isLoading }] = useLazyGetS3FilesQuery();
  const [selectedS3Files, setSelectedS3Files] = useState<string[]>([]);
  const [s3CurrentPath, setS3CurrentPath] = useState<string>('/');
  const [isInFolder, setIsInFolder] = useState<boolean>(false);
  const [s3Files, setS3Files] = useState<string[]>([]);
  const [bucketName, setBucketName] = useState<string>('');
  const [accessKey, setAccessKey] = useState<string>('');
  const [privateKey, setPrivateKey] = useState<string>('');
  const [s3LoginError, setS3LoginError] = useState<any>(undefined);

  const handleGetS3Files = async () => {
    setS3LoginError(undefined);
    try {
      const result = await fetchS3Files(
        {
          bucketName,
          accessKey,
          privateKey,
        },
        true
      ).unwrap();
      setS3Files(result);
    } catch (error) {
      setS3LoginError((error as any).data);
    }
  };

  const handleS3ItemListClick = (event: any, file: string) => {
    if (event.target.checked) {
      setSelectedS3Files((s3SelectedFiles: any) => [...s3SelectedFiles, file]);
    } else {
      handleS3DeleteClick(file);
    }
  };

  const handleFolderClick = async (folder: string, goBack: boolean) => {
    if (!goBack) {
      try {
        const result = await fetchS3Files(
          {
            bucketName,
            accessKey,
            privateKey,
            folder: isInFolder
              ? `?prefix=${s3CurrentPath.slice(
                  0
                )}${getStringAfterPenultimateSlash(folder)}/`
              : `?prefix=${folder}`,
          },
          true
        ).unwrap();
        setS3Files(result);
        setIsInFolder(true);
        setS3CurrentPath(folder);
      } catch (error) {
        console.error(error);
      }
    } else {
      if (!hasTwoOrMoreSlashes(s3CurrentPath)) {
        setIsInFolder(false);
        setS3CurrentPath('/');
        handleGetS3Files();
        return;
      } else {
        const newPath = getNewFolderPath(s3CurrentPath);
        try {
          const result = await fetchS3Files(
            {
              bucketName,
              accessKey,
              privateKey,
              folder: `?prefix=${newPath}`,
            },
            true
          ).unwrap();
          setS3Files(result);
          setS3CurrentPath((path: string) => newPath);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleS3DeleteClick = (file: string) => {
    setSelectedS3Files((s3Files: any) =>
      s3Files.filter((f: string) => f !== file)
    );
  };

  useEffect(() => {
    register('s3content.files');
  }, []);

  useEffect(() => {
    setValue('s3content.files', [...selectedS3Files]);
  }, [selectedS3Files]);

  return (
    <>
      {s3Files.length < 1 ? (
        <div
          className={style['tab-s3-login-container']}
          data-testid="S3LoginPage"
        >
          <div className={style['tab-s3']}>
            <InputField label="Bucket name" labelFor="bucketName">
              <input
                type="text"
                id="bucketName"
                {...register('s3content.bucket')}
                data-testid="BucketName"
                onChange={(event) => setBucketName(event.target.value)}
              />
            </InputField>
            <InputField label="Access key" labelFor="accessKey">
              <input
                type="text"
                id="accessKey"
                {...register('s3content.accessKey')}
                data-testid="AccessKey"
                onChange={(event) => setAccessKey(event.target.value)}
              />
            </InputField>
            <InputField label="Private key" labelFor="privateKey">
              <input
                type="text"
                id="privateKey"
                {...register('s3content.privateKey')}
                data-testid="PrivateKey"
                onChange={(event) => setPrivateKey(event.target.value)}
              />
            </InputField>
            <div className={style['tab-s3-login']}>
              <ButtonField>
                <button
                  type="button"
                  onClick={() => handleGetS3Files()}
                  data-testid="S3LoginBtn"
                  disabled={isLoading}
                >
                  Login
                </button>
              </ButtonField>
            </div>
          </div>
          {s3LoginError && (
            <div
              className={style['tab-s3-login-error']}
              data-testid="S3LoginError"
            >
              <span>{s3LoginError}</span>
            </div>
          )}
        </div>
      ) : (
        <div className={style['tab-s3-container']} data-testid="S3Container">
          <div className={style['tab-s3-files-container']}>
            <section className={style['tab-s3-files-header']}>
              <span className={style['tab-s3-files-header-title']}>
                Current path:
              </span>
              <span className={style['tab-s3-files-header-path']}>
                {s3CurrentPath}
              </span>
            </section>

            <section
              className={style['tab-s3-files-list']}
              data-testid="S3FilesList"
            >
              <S3FilesList
                isInFolder={isInFolder}
                bucketName={bucketName}
                s3CurrentPath={s3CurrentPath}
                s3Files={s3Files}
                selectedS3Files={selectedS3Files}
                handleFolderClick={handleFolderClick}
                handleS3ItemListClick={handleS3ItemListClick}
                register={register}
              />
            </section>
          </div>
          <S3UploadFiles
            files={selectedS3Files}
            handleS3DeleteClick={handleS3DeleteClick}
          />
        </div>
      )}
    </>
  );
};

const S3FilesList = ({
  isInFolder,
  bucketName,
  s3CurrentPath,
  s3Files,
  selectedS3Files,
  handleFolderClick,
  handleS3ItemListClick,
  register,
}: {
  isInFolder: boolean;
  bucketName: string;
  s3CurrentPath: string;
  s3Files: any[];
  selectedS3Files: string[];
  handleFolderClick: any;
  handleS3ItemListClick: any;
  register: UseFormRegister<any>;
}) => {
  return (
    <>
      {isInFolder && (
        <div
          className={style['tab-s3-files-list-folder']}
          onClick={() => handleFolderClick(bucketName, true)}
        >
          <Iconsvg name="folder"></Iconsvg> ..
        </div>
      )}
      {s3Files.map((file, i) => (
        <>
          {getStringAfterPenultimateSlash(file) !==
            getStringAfterPenultimateSlash(s3CurrentPath) && (
            <div
              className={
                style[
                  getFileType(file) === 'folder'
                    ? 'tab-s3-files-list-folder'
                    : 'tab-s3-files-list-item'
                ]
              }
              key={'cogen-5238' + i.toString()}
              onClick={() =>
                getFileType(file) === 'folder' && handleFolderClick(file, false)
              }
            >
              {getFileType(file) === 'folder' ? (
                <div>
                  <Iconsvg name="folder"></Iconsvg>
                </div>
              ) : (
                <CheckboxField>
                  <input
                    name={`checkbox-${file}`}
                    type="checkbox"
                    onChange={(event: any) =>
                      handleS3ItemListClick(event, file)
                    }
                    checked={selectedS3Files?.includes(file)}
                  />
                </CheckboxField>
              )}
              <div
                className={
                  style[
                    getFileType(file) === 'folder'
                      ? 'tab-s3-files-list-folder-name'
                      : 'tab-s3-files-list-item-name'
                  ]
                }
              >
                {isInFolder
                  ? getStringAfterPenultimateSlash(file).slice(0, -1)
                  : file}
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

const S3UploadFiles = ({
  files,
  handleS3DeleteClick,
}: {
  files: string[];
  handleS3DeleteClick: any;
}) => {
  return (
    <div className={style['s3-upload-file-container']}>
      {files?.map((file, i) => (
        <div
          key={'key-' + i.toString()}
          className={style['s3-upload-file-item']}
        >
          <div>
            <span>{file}</span>
          </div>
          <ButtonField mode="text" size="small">
            <button type="button" onClick={() => handleS3DeleteClick(file)}>
              <Iconsvg name="close" size={12}></Iconsvg>
            </button>
          </ButtonField>
        </div>
      ))}
    </div>
  );
};

function getFileType(file: string) {
  if (file[file.length - 1] === '/') {
    return 'folder';
  } else {
    return 'file';
  }
}

function hasTwoOrMoreSlashes(inputString: string) {
  const slashCount = inputString.split('/').length - 1;
  return slashCount >= 2;
}

function getStringAfterPenultimateSlash(inputString: string) {
  const parts = inputString.split('/');
  if (parts.slice(-1)?.[0] === '') {
    return parts.slice(-2)[0];
  }

  return parts.slice(-1)[0];
}

function getNewFolderPath(currentPath: string): string {
  const newIndex = currentPath.split('/').slice(-1)[0] === '' ? -2 : -1;
  const newPath = currentPath.split('/').slice(0, newIndex).join('/');
  return newPath.at(-1) === '/' ? newPath : newPath + '/';
}

export default S3FileUpload;
