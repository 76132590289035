export enum MODAL_ACTIONS {
  save = 'save',
  reset = 'reset',
  cancel = 'cancel',
  header = 'header',
}

export interface ICloseModalInfo {
  id: string;
  action: ICloseModalOptions;
  extraData?: any;
}

export type ICloseModalOptions = 'overlay' | 'esc' | MODAL_ACTIONS;
