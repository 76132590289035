import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IToast } from 'interfaces/toasts';
import { RootState } from '../store';

export const SLICE_KEY = 'toast';

interface IToastSlice {
  notifications: IToast[];
}

const initialState: IToastSlice = {
  notifications: [],
};

const toastSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<IToast>) => {
      state.notifications?.push(action.payload);
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications?.filter(
        (notification: any) => notification.id !== action.payload
      );
    },
  },
});

export const selectNotifications = (state: RootState) =>
  state.toast?.notifications;

export const { addToast, removeToast } = toastSlice.actions;

export default toastSlice.reducer;
