import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useRef, useState } from 'react';
import style from './CellDeployModuleVersion.module.scss';
import {
  Dropdown,
  DropdownList,
  DropdownListItem,
  Iconsvg,
} from '@wk/components-react16';
import { IDeployModuleSearchRow } from 'interfaces/modules/module.interface';

interface ICellDeployModuleVersion {
  data: Partial<IDeployModuleSearchRow>;
}

const CellDeployModuleVersion = (props: ICellDeployModuleVersion) => {
  const rowData = props.data;
  const version = rowData?.selectedVersion;
  const allVersions = rowData?.allVersions;
  const [isOpen, setIsOpen] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  const [currentSelection, setCurrentSelection] = useState(version);

  const handleSelection = (version: string) => {
    setCurrentSelection(version);
    handleOpenClose();
    props.data?.setValue && props.data?.setValue('selectedVersion', version);
  };

  const handleOpenClose = () => {
    const deployModuleTable = document.getElementById(
      'deploy-modules-table'
    ) as HTMLElement;
    // element with scroll behavior
    const deployModuleTableContainer = document
      .getElementById('deploy-modules-table')
      ?.querySelector(
        '.ag-root-wrapper.ag-ltr.ag-layout-normal'
      ) as HTMLElement;
    // element that will hold the dropdown
    const currentDropdownParent =
      deployModuleTableContainer?.scrollHeight >
      deployModuleTableContainer?.clientHeight
        ? deployModuleTableContainer
        : deployModuleTable;
    const dropdownElement = cellRef.current?.querySelector(
      '[data-e2e="cg-dropdown"]'
    )?.parentElement as HTMLElement;

    if (!isOpen) {
      // Opening the dropdown
      if (dropdownElement && currentDropdownParent) {
        // Remove only this cell's dropdown if it's already in the table
        const existingDropdown = currentDropdownParent.querySelector(
          `[data-cell-id="${rowData.id}"]`
        );
        if (existingDropdown) {
          existingDropdown.remove();
        }

        // Add a custom attribute to identify this cell's dropdown
        dropdownElement.setAttribute('data-cell-id', rowData.id);
        dropdownElement.style.position = 'absolute';
        currentDropdownParent.appendChild(dropdownElement);
        setIsOpen(true);
      }
    } else {
      // Closing the dropdown
      const existingDropdown = currentDropdownParent?.querySelector(
        `[data-cell-id="${rowData.id}"]`
      );
      if (existingDropdown && cellRef.current) {
        cellRef.current.appendChild(existingDropdown);
        setIsOpen(false);
      }
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        className={style['cell-container']}
        data-testid="CellDeployModuleVersion"
        key={rowData.id}
        ref={cellRef}
      >
        <span className={style['cell-name']}>{currentSelection}</span>
        {isOpen ? (
          <div
            id={`cellDeployModuleVersion-button-${rowData.id}`}
            onClick={() => handleOpenClose()}
          >
            <Iconsvg name="chevron-down"></Iconsvg>
          </div>
        ) : (
          <div
            id={`cellDeployModuleVersion-button-${rowData.id}`}
            onClick={() => handleOpenClose()}
            data-testid="CellDeployModuleVersionDropdownBtn"
          >
            <Iconsvg name="chevron-up"></Iconsvg>
          </div>
        )}
        <Dropdown
          targetSelector={`#cellDeployModuleVersion-button-${rowData.id}`}
          menuMode="application"
          onUserRequest={() => handleOpenClose()}
          controlMode="controlled"
          isOpen={isOpen}
          srLabelledby={`cellDeployModuleVersion-button-${rowData.id}`}
        >
          <DropdownList menuMode="application">
            {allVersions?.map((version) => (
              <DropdownListItem
                onItemClick={() => handleSelection(version)}
                slot="dropdownListItem"
                key={version}
                isSelected={version === currentSelection}
                {...(props.data?.register &&
                  props.data.register('selectedVersion', currentSelection))}
              >
                {version}
              </DropdownListItem>
            ))}
          </DropdownList>
        </Dropdown>
      </div>
    </CsbErrorBoundary>
  );
};

export default CellDeployModuleVersion;
