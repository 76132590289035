import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import DeployModulesTables from 'components/TableContainer/DeployModulTables/DeployModulesTables';
import { MANAGE_MODULES_DEPLOY_SEARCH_RESULTS } from 'pages/ManageModules/ManageModules.constants';
import styles from '../../DeployModuleModal.module.scss';
import { IDeployModuleSearchRow } from 'interfaces/modules/module.interface';

interface ISearchResultsSection {
  modules: IDeployModuleSearchRow[] | undefined;
}
export default function SearchResultsSection({
  modules,
}: ISearchResultsSection) {
  return (
    <CsbErrorBoundary>
      <div
        className={styles['search-results-container']}
        data-testid="SearchResultsSection"
      >
        <span className={styles['header']}>Search results</span>
        <span className={styles['subtitle']}>
          Select the module and version number you want to deploy
        </span>
        <DeployModulesTables
          items={(modules as IDeployModuleSearchRow[]) ?? [{}]}
          type="search-table"
          columns={MANAGE_MODULES_DEPLOY_SEARCH_RESULTS}
        />
      </div>
    </CsbErrorBoundary>
  );
}
