import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IAvailableModuleServer } from 'interfaces/modules/module.interface';

interface IModifyModule {
  moduleId: number;
  hostname: string;
}

interface IModifyAllModuleHosts {
  moduleId: number;
  slave: string;
}

interface IDeployModule {
  artifactId: string;
  version: string;
  hostname: string;
}

export const modulesApi = createApi({
  reducerPath: 'modulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/csb/modules',
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAvailableModules: builder.query<IAvailableModuleServer, any>({
      query: () => {
        return { url: `available`, credentials: 'include' };
      },
      keepUnusedDataFor: 0,
    }),

    //mutations
    startModule: builder.mutation<any, IModifyModule>({
      query: ({ moduleId, hostname }: IModifyModule) => {
        return {
          url: `start/${moduleId}/${hostname}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    stopModule: builder.mutation<any, IModifyModule>({
      query: ({ moduleId, hostname }: IModifyModule) => {
        return {
          url: `stop/${moduleId}/${hostname}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    restartModule: builder.mutation<any, IModifyModule>({
      query: ({ moduleId, hostname }: IModifyModule) => {
        return {
          url: `restart/${moduleId}/${hostname}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    removeModule: builder.mutation<any, IModifyModule>({
      query: ({ moduleId, hostname }: IModifyModule) => {
        return {
          url: `remove/${moduleId}/${hostname}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
    updateModule: builder.mutation<any, IModifyModule>({
      query: ({ moduleId, hostname }: IModifyModule) => {
        return {
          url: `update/${moduleId}/${hostname}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    startAllModules: builder.mutation<any, IModifyAllModuleHosts>({
      query: ({ moduleId, slave }: IModifyAllModuleHosts) => {
        return {
          url: `start/all/${moduleId}/${slave}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    stopAllModules: builder.mutation<any, IModifyAllModuleHosts>({
      query: ({ moduleId, slave }: IModifyAllModuleHosts) => {
        return {
          url: `stop/all/${moduleId}/${slave}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    restartAllModules: builder.mutation<any, IModifyAllModuleHosts>({
      query: ({ moduleId, slave }: IModifyAllModuleHosts) => {
        return {
          url: `restart/all/${moduleId}/${slave}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    removeAllModules: builder.mutation<any, IModifyAllModuleHosts>({
      query: ({ moduleId, slave }: IModifyAllModuleHosts) => {
        return {
          url: `remove/all/${moduleId}/${slave}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
    updateAllModules: builder.mutation<any, IModifyAllModuleHosts>({
      query: ({ moduleId, slave }: IModifyAllModuleHosts) => {
        return {
          url: `update/all/${moduleId}/${slave}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    deployModule: builder.mutation<any, IDeployModule>({
      query: ({ artifactId, version, hostname }: IDeployModule) => {
        return {
          url: `deploy/${artifactId}/${version}/${hostname}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useStartModuleMutation,
  useStopModuleMutation,
  useRestartModuleMutation,
  useRemoveModuleMutation,
  useUpdateModuleMutation,
  useGetAvailableModulesQuery,
  useLazyGetAvailableModulesQuery,
  useStartAllModulesMutation,
  useStopAllModulesMutation,
  useRestartAllModulesMutation,
  useRemoveAllModulesMutation,
  useUpdateAllModulesMutation,
} = modulesApi;
