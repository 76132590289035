import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import style from './CellDeployModuleName.module.scss';
import { RadioField } from '@wk/components-react16/dist/radio-field/radio-field';
import { Tooltip } from '@wk/components-react16/dist/tooltip/tooltip';
import { CheckboxField, TooltipBody } from '@wk/components-react16';
import useWkTooltipOutsideComponent from 'utils/hooks/wk-tooltips/useWkTooltipOutsideComponent';
import {
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
} from 'interfaces/modules/module.interface';

interface ICellDeployModuleName {
  data: IDeployModuleSearchRow | IDeployModuleServerRow;
}

const CellDeployModuleName = (props: ICellDeployModuleName) => {
  const rowData = props.data;
  const name = rowData.name;
  const type = rowData.type;
  const spanRef = useRef<HTMLSpanElement>(null);
  const cellRef = useRef<MutableRefObject<any> | any>();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const { moveWkTooltipToOutsideElement } = useWkTooltipOutsideComponent(
    `deploy-module-search-result-name-${rowData.id}-tooltip`
  );

  useEffect(() => {
    if (isTooltipOpen && isTruncated) {
      moveWkTooltipToOutsideElement();
    }
  }, [isTooltipOpen]);

  useEffect(() => {
    if (spanRef.current) {
      setIsTruncated(spanRef.current.scrollWidth > spanRef.current.clientWidth);
    }
  }, [rowData]);

  const handleSelected = () => {
    if ((rowData as IDeployModuleSearchRow).selectionMethod !== undefined) {
      const selectionMethod = (rowData as IDeployModuleSearchRow)
        .selectionMethod;
      if (selectionMethod) {
        selectionMethod();
      }
    }
    if ((rowData as IDeployModuleSearchRow).setValue !== undefined) {
      (rowData as IDeployModuleSearchRow).setValue(
        'selectedVersion',
        (rowData as IDeployModuleSearchRow).selectedVersion
      );
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        className={style['cell-container']}
        data-testid="CellDeployModuleName"
        id={`deploy-module-search-result-name-${rowData.id}-cell`}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        ref={cellRef}
        key={rowData.id}
      >
        {type === 'search-results' ? (
          <RadioField>
            <input
              type="radio"
              data-testid="CellDeployModuleNameRadioBtn"
              onClick={() => handleSelected()}
              value={name}
              {...(props.data?.register &&
                props.data.register('selectedModule'))}
            />
          </RadioField>
        ) : (
          <CheckboxField>
            <input
              type="checkbox"
              data-testid="CellDeployModuleNameCheckBox"
              onClick={() => handleSelected()}
              value={(rowData as IDeployModuleServerRow).ec2Server}
              {...(props.data?.register &&
                props.data.register('selectedServers'))}
            />
          </CheckboxField>
        )}

        <span ref={spanRef} className={style['cell-name']}>
          {name}
        </span>
        {name && isTruncated && (
          <Tooltip
            position="right"
            targetSelector={`#deploy-module-search-result-name-${rowData.id}-cell`}
            id={`deploy-module-search-result-name-${rowData.id}-tooltip`}
            controlMode="uncontrolled"
            isOpen={isTooltipOpen}
          >
            <TooltipBody slot="tooltipBody">
              <span data-testid="CellDeployModuleNameTooltip">
                <span
                  id={`name-${rowData.id}`}
                  style={{ whiteSpace: 'break-spaces' }}
                >
                  {name}
                </span>
              </span>
            </TooltipBody>
          </Tooltip>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default CellDeployModuleName;
