import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './SetPriorityStep.module.scss';
import { FormFieldErrorInfo, InputField } from '@wk/components-react16';
import { useState } from 'react';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from 'react-hook-form';

export interface ISetPriorityStep {
  register: UseFormRegister<any>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const SetPriorityStep = ({
  register,
  setError,
  clearErrors,
  errors,
}: ISetPriorityStep) => {
  const [inputStatus, setInputStatus] = useState<
    'success' | 'error' | undefined
  >(undefined);
  const [errorMessages, setErrorMessages] = useState<FormFieldErrorInfo[]>();
  const handleChange = (event: any) => {
    if (
      event.target.value === '' ||
      (Number.isInteger(Number(event.target.value)) &&
        Number(event.target.value) <= 100)
    ) {
      setErrorMessages(undefined);
      setInputStatus('success');
      Object.keys(errors).length > 0 && clearErrors('priority');
    } else {
      setErrorMessages([
        {
          label: 'Value must be an integer and <= 100',
          labelId: 'label-error-id-3-1',
        },
      ]);
      setInputStatus('error');
      setError('priority', {
        type: 'custom',
        message: 'Value must be an integer and <= 100',
      });
    }
  };
  return (
    <CsbErrorBoundary>
      <div data-testid="SetPriorityStep" className={style['container']}>
        <InputField
          label="Set priority"
          labelFor="input"
          status={inputStatus}
          errors={errorMessages}
        >
          <input
            type="number"
            id="input"
            {...register('priority')}
            placeholder="Enter a value"
            data-testid="SetPriorityInput"
            onChange={handleChange}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
          />
        </InputField>
      </div>
    </CsbErrorBoundary>
  );
};

export default SetPriorityStep;
