export enum DateRadioValues {
  BEFORE = 'Before',
  AFTER = 'After',
}

export enum DurationFilterTypes {
  LESS_THAN = 'lessThan',
  MORE_THAN = 'greaterThan',
  BETWEEN = 'inRange',
}

export enum DateFilterTypes {
  BEFORE = 'lessThan',
  AFTER = 'greaterThan',
  BETWEEN = 'inRange',
}

export interface IDateFilterProps {
  checked: DateRadioValues;
  date: string;
  time: string;
}