import { Iconsvg, Tooltip, TooltipBody } from '@wk/components-react16';
import style from './SourceFromBuIcon.module.scss';

export interface ISouceFromBuIcon {
  isSourcedFromBu: boolean | undefined;
  id: string;
  size?: number;
  type?: 'top' | 'right' | null;
}

export const SouceFromBuIcon = ({
  isSourcedFromBu,
  id,
  size,
  type,
}: ISouceFromBuIcon) =>
  isSourcedFromBu ? (
    <>
      <div
        className={
          style[`${type === 'top' ? 'top-positioned' : 'right-positioned'}`]
        }
        id={`sourceFromBuIcon-${id}`}
        data-testid="SouceFromBuIcon"
      >
        <Iconsvg name="info" size={size ?? 18} color="black"></Iconsvg>
      </div>
      <Tooltip
        position="left"
        targetSelector={`#sourceFromBuIcon-${id}`}
        id="sourceFromBuIcontooltip"
      >
        <TooltipBody slot="tooltipBody">
          <span>Value was sourced from BUAdmin</span>
        </TooltipBody>
      </Tooltip>
    </>
  ) : (
    <></>
  );
