import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import React, { Suspense, useEffect, useState } from 'react';
import { retryLazyLoad } from 'utils/commonFunctions/CommonFunctions';

const ManageModulesLazy = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => {
  const [Component, setComponent] =
    useState<React.LazyExoticComponent<any> | null>(null);
  const [loadError, setLoadError] = useState<boolean>(false);

  useEffect(() => {
    retryLazyLoad(() => import('./ManageModules'), 4, 5000)
      .then((module: any) =>
        setComponent(() => module.default || module.ManageModules)
      )
      .catch(() => setLoadError(true));
  }, []);

  if (loadError) {
    return <div>Error loading, please reload the page.</div>;
  }

  if (!Component) {
    return <TableOverlayComponent page={PAGE_OPTIONS.generalPage} />;
  }

  return (
    <CsbErrorBoundary>
      <Suspense
        fallback={<TableOverlayComponent page={PAGE_OPTIONS.generalPage} />}
      >
        <Component {...props} />
      </Suspense>
    </CsbErrorBoundary>
  );
};

export default ManageModulesLazy;
