import { DateFilterModel, GridApi, ISetFilter } from 'ag-grid-community';
import { NumberFilterModel, SetFilterModel } from 'ag-grid-enterprise';
import { TABLE_FIELDS, TABLE_FILTERS } from 'utils/common-constants';
import { IFilterModel } from 'interfaces/dashboard/dashboard-slice.interface';
import {
  DateFilterTypes,
  DurationFilterTypes,
} from 'interfaces/filter-props.interface';
import { IPostJobsInfo } from 'interfaces/post-jobs-info.interface';
import { IJobStartedByUser } from 'interfaces/user.interface';

const isFilterExceedMaxLength = (filterModel: any) => {
  const MAX_LENGTH = 15;
  return (
    filterModel.filter?.toString().length > MAX_LENGTH ||
    filterModel.filterTo?.toString().length > MAX_LENGTH
  );
};

const isFilterHasExponentNotaion = (filterModel: any) => {
  return (
    filterModel.filter?.toString().includes('e') ||
    filterModel.filterTo?.toString().includes('e')
  );
};

export const setFilterDataToSend = (
  filters: IFilterModel,
  api: GridApi,
  dataToSend: IPostJobsInfo,
  users: IJobStartedByUser[]
): void => {
  Object.keys(filters).forEach((filterName) => {
    const filterModel = filters[filterName as keyof IFilterModel];
    if (filterModel) {
      if (
        isFilterExceedMaxLength(filterModel) ||
        isFilterHasExponentNotaion(filterModel)
      ) {
        api.setFilterModel({ ...filters, [filterName]: null });
      } else {
        if (filterName === TABLE_FIELDS.duration) {
          handleDurationDataToSend(
            filterModel as NumberFilterModel,
            dataToSend
          );
        } else if (filterName === TABLE_FIELDS.id) {
          dataToSend.jobId = (
            filterModel as NumberFilterModel
          ).filter?.toString();
        } else if (
          filterName === TABLE_FIELDS.name &&
          (filterModel as SetFilterModel).values.length
        ) {
          dataToSend.workflowNames = (filterModel as SetFilterModel).values;
        } else if (
          filterName === TABLE_FIELDS.user &&
          (filterModel as SetFilterModel).values.length
        ) {
          handleJobStartedByDataToSend(
            filterModel as SetFilterModel,
            dataToSend,
            users
          );
        } else if (
          filterName === TABLE_FIELDS.startTime ||
          filterName === TABLE_FIELDS.endTime
        ) {
          handleTimeDataToSend(
            filterModel as DateFilterModel,
            dataToSend,
            filterName
          );
        }
      }
    }
  });
};

export const deleteTableFiltersFromDataToSend = (dataToSend: IPostJobsInfo) => {
  for (const filter of TABLE_FILTERS) {
    delete dataToSend[filter as keyof IPostJobsInfo];
  }
};

const formatAgGridDateToSendDate = (date: string | null) => {
  return date?.replace(' ', 'T').slice(0, -3);
};

export const handleTimeDataToSend = (
  filterModel: DateFilterModel,
  dataToSend: IPostJobsInfo,
  dateType: string
) => {
  const formattedDate = formatAgGridDateToSendDate(filterModel.dateFrom);
  switch (filterModel.type) {
    case DateFilterTypes.BEFORE:
      if (dateType === TABLE_FIELDS.startTime) {
        dataToSend.beforeStartDate = true;
        dataToSend.startDate = formattedDate;
      } else {
        delete dataToSend.afterEndDate;
        dataToSend.endDate = formattedDate;
      }
      break;
    case DateFilterTypes.AFTER:
      if (dateType === TABLE_FIELDS.startTime) {
        delete dataToSend.beforeStartDate;
        dataToSend.startDate = formattedDate;
      } else {
        dataToSend.afterEndDate = true;
        dataToSend.endDate = formattedDate;
      }
      break;
    case DateFilterTypes.BETWEEN:
      delete dataToSend.beforeStartDate;
      const formattedDateTo = formatAgGridDateToSendDate(filterModel.dateTo);
      if (dateType === TABLE_FIELDS.startTime) {
        delete dataToSend.beforeStartDate;
        dataToSend.startDate = formattedDate;
        dataToSend.startDateTo = formattedDateTo;
      } else {
        delete dataToSend.afterEndDate;
        dataToSend.endDate = formattedDate;
        dataToSend.endDateTo = formattedDateTo;
      }
      break;
  }
};

const handleJobStartedByDataToSend = (
  filterModel: SetFilterModel,
  dataToSend: IPostJobsInfo,
  users: IJobStartedByUser[]
) => {
  if (users) {
    const selectedUsers = users
      .filter((user) => filterModel.values.includes(user.name))
      .filter(
        (obj: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.id === obj.id)
      );
    dataToSend.userIds = selectedUsers.map((user: any) => user.id);
  }
};

export const handleDurationDataToSend = (
  duration: NumberFilterModel,
  dataToSend: IPostJobsInfo
): void => {
  const { filter, filterTo } = duration;
  const isFilterValueExist = filter || filter === 0;
  switch (duration.type) {
    case DurationFilterTypes.MORE_THAN:
      delete dataToSend.durationEnd;
      if (isFilterValueExist) {
        dataToSend.durationStart = filter;
      }
      break;
    case DurationFilterTypes.LESS_THAN:
      delete dataToSend.durationStart;
      if (isFilterValueExist) {
        dataToSend.durationEnd = filter;
      }
      break;
    case DurationFilterTypes.BETWEEN:
      if (isFilterValueExist) {
        dataToSend.durationStart = filter;
      }
      if (filterTo || filterTo === 0) {
        dataToSend.durationEnd = filterTo;
      }
      break;
  }
};

export const setNameFilterValues = (
  gridApi: GridApi | null,
  workflowsNames?: string[]
) => {
  const nameFilter = gridApi?.getFilterInstance(
    TABLE_FIELDS.name
  ) as ISetFilter;
  if (nameFilter && workflowsNames?.length) {
    nameFilter.setFilterValues(workflowsNames);
  }
};

export const setUserFilterValues = (
  gridApi: GridApi | null,
  users: IJobStartedByUser[]
) => {
  const userFilter = gridApi?.getFilterInstance(
    TABLE_FIELDS.user
  ) as ISetFilter;
  if (userFilter && users?.length) {
    const usersName = users
      .map((user) => user.name)
      .sort((a: string, b: string) => a.localeCompare(b));
    userFilter.setFilterValues(usersName);
  }
};

export const setNameFilterPopupWidth = (currentFilterId: string) => {
  const popup = document.getElementsByClassName(
    'ag-popup-child'
  )?.[0] as HTMLElement;
  if (currentFilterId === TABLE_FIELDS.name && popup?.style) {
    popup.style.width = '580px';
  }
};

export const timeFilterOptions = [
  {
    displayKey: 'lessThan',
    displayName: 'Before',
    predicate: () => true,
  },
  {
    displayKey: 'greaterThan',
    displayName: 'After',
    predicate: () => true,
  },
  {
    displayKey: 'inRange',
    displayName: 'Between',
    predicate: () => true,
    numberOfInputs: 2,
  },
];

export const defaultFilterParams = {
  buttons: ['cancel', 'reset', 'apply'],
  suppressAndOrCondition: true,
  closeOnApply: true,
};
