import { ColDef } from 'ag-grid-enterprise';
import { ILogRow } from './log-row.interface';
import { IOrchestrationRow } from './dashboard/orchestration-row.interface';
import { IWorkflow } from './runOrchestration/workflow-row';
import {
  IModuleInstancesRow,
  IModuleParametersRow,
  IModuleRow,
  IModuleUsedByRow,
} from './modules/module.interface';

export interface ExtendedColDef extends ColDef {
  filterModalTitle?: string;
}

export interface ITableContainerProps {
  items:
    | IOrchestrationRow[]
    | ILogRow[]
    | IWorkflow[]
    | IModuleRow[]
    | IModuleParametersRow[]
    | IModuleInstancesRow[]
    | IModuleUsedByRow[];
  columns: ExtendedColDef[] | ColDef[];
  isServerSideFiltering?: boolean;
  additionalData?: any;
  onGridReadyAdditionalMethods?: IOnGridReadyAdditionalMethod[];
  additionalStyles?: string;
  page?: PAGE_OPTIONS;
}

export interface IOnGridReadyAdditionalMethod {
  id: string;
  method: (...props: any) => any;
}

export enum PAGE_OPTIONS {
  orchestrations = 'orchestrations',
  logs = 'logs',
  runOrchestrations = 'run-orchestrations',
  manageModules = 'manage-modules',
  generalPage = 'general-page',
}
