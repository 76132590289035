import { TabHeader, TabPanel, Tabs } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './UploadFilesStep.module.scss';
import LocalFileUpload from './components/LocalFileUpload/LocalFileUpload';
import S3FileUpload from './components/S3FileUpload/S3FileUpload';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

export interface IUploadFilesStep {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
}

const UploadFilesStep = ({ register, setValue }: IUploadFilesStep) => {
  return (
    <CsbErrorBoundary>
      <div data-testid="UploadFilesStep">
        <Tabs mode="default">
          <TabHeader slot="tabHeader">
            <span data-testid="Local-tab">From local or network drive</span>
          </TabHeader>
          <TabHeader slot="tabHeader">
            <span data-testid="S3-tab">From S3 server</span>
          </TabHeader>
          <TabPanel slot="tabPanel">
            <LocalFileUpload register={register} setValue={setValue} />
          </TabPanel>
          <TabPanel slot="tabPanel">
            <S3FileUpload register={register} setValue={setValue} />
          </TabPanel>
        </Tabs>
      </div>
    </CsbErrorBoundary>
  );
};

export default UploadFilesStep;
