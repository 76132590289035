import { useAppDispatch } from 'redux/hooks';
import { IToast } from 'interfaces/toasts';
import { Notification } from '@wk/components-react16';
import { removeToast } from 'redux/toast/toastSlice';
import { capitalizeFirstLetter } from 'utils/commonFunctions/CommonFunctions';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

export interface IServiceNowToast {
  item: IToast;
}

export const ServiceNowToast = ({ item }: IServiceNowToast) => {
  const dispatch = useAppDispatch();
  return (
    <CsbErrorBoundary>
      <div data-testid={`ServiceNowToast-${item.id}`}>
        <Notification
          controlMode="uncontrolled"
          isOpen={true}
          mode="toast"
          type={item.status}
          autoCloseTimeout={5000}
          hasCloseButton
          heading={capitalizeFirstLetter(item.status)}
          onCloseEnd={() => dispatch(removeToast(item.id))}
          key={item.id}
        >
          <span slot="notificationContent">
            {item.status === 'success'
              ? `ServiceNow support ticket request sent successfully`
              : `Sorry, we couldn't send your ServiceNow support ticket request, please try again`}
          </span>
        </Notification>
      </div>
    </CsbErrorBoundary>
  );
};
