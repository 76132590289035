import { orchestrationApi } from '../../api/orchestrations/orchestrationApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPostJobsInfo } from 'interfaces/post-jobs-info.interface';
import {
  IActiveFilter,
  IDashboardState,
  IStatusCounters,
  IUpdateOrchestrationStatus,
  IUserValue,
  IAddJob,
  IOrchestrationsServerResponse,
} from 'interfaces/dashboard/dashboard-slice.interface';
import {
  JOBS_OWNER_FILTER,
  ORCHESTRATION_FILTERS,
  ORCHESTRATION_STATUSES,
} from '../../utils/common-constants';
import { IOrchestrationRow } from '../../interfaces/dashboard/orchestration-row.interface';
import { IOrchestrationStatus } from '../../interfaces/dashboard/orchestration-status.interface';
import { IPaginatorStatusInterface } from '../../interfaces/paginator-status.interface';
import { ORCHESTRATION_FILTER_ITEMS } from '../../pages/Dashboard/Dashboard.constants';
import {
  mapJobToTableData,
  mapResponseJobsToTableData,
} from 'dto/jobToTable/jobToTable';
import { RootState } from 'redux/store';
import { getNameFromEmail } from 'utils/commonFunctions/CommonFunctions';
import { IJobStartedByUser } from 'interfaces/user.interface';
import { IStepInformation } from 'interfaces/orchestration.interface';
import {
  fetchAllOrchestrations,
  postViewLog,
} from 'api/orchestrations/orchestrationsThunk';

const setIsSelected = (
  orchestrations: IOrchestrationRow[],
  selectedOrchestrations: IOrchestrationRow[]
) => {
  return orchestrations?.map((orchestration) => {
    if (
      selectedOrchestrations.find(
        (selected) => selected.id === orchestration.id
      )
    ) {
      return { ...orchestration, isSelected: true };
    } else {
      return orchestration;
    }
  });
};

const calculateCount = (payload: any, status: string): number => {
  const upperCased = status.toUpperCase();
  if (upperCased === ORCHESTRATION_STATUSES.STOPPED) {
    return payload[status] + payload['stopping'] || 0;
  } else if (upperCased === ORCHESTRATION_STATUSES.FINISHED) {
    return payload[status] + payload['finished_error'] || 0;
  } else {
    return payload[status] || 0;
  }
};

const addToCount = (actualStatus: any, job: any) => {
  const status = job.status;
  if (
    status === actualStatus.filterId ||
    actualStatus.filterId === ORCHESTRATION_FILTERS.ANY
  ) {
    return actualStatus.count + 1;
  } else {
    return actualStatus.count;
  }
};

const combineFilters = (filter: string): string[] => {
  const upperCased = filter.toUpperCase();
  const result = [upperCased];

  if (upperCased === ORCHESTRATION_STATUSES.STOPPED) {
    result.push('STOPPING');
  } else if (upperCased === ORCHESTRATION_STATUSES.FINISHED) {
    result.push('FINISHED_ERROR');
  }

  return result;
};

export const SLICE_KEY = 'dashboardContainer';

const initialState: IDashboardState = {
  statuses: ORCHESTRATION_FILTER_ITEMS,
  selectedStatus: ORCHESTRATION_FILTER_ITEMS[0],
  loading: true,
  orchestrations: [],
  selectedOrchestrations: [],
  activeFilters: [],
  filterModel: null,
  paginatorInfo: {
    page: 1,
    pageSize: 20,
  },
  filterInfo: {
    jobStatuses: ['ANY'],
    activePage: 1,
    itemsPerPage: 20,
    addJobInfo: true,
    jobsOwner: JOBS_OWNER_FILTER.ALL_JOBS,
  },
  allUsers: [],
  sseOrchestrationsEvents: [],
};

const dashboardSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setStatuses: (state, action: PayloadAction<IOrchestrationStatus[]>) => {
      return { ...state, statuses: action.payload };
    },
    setLabelForFirstStatus: (state, action: PayloadAction<string>) => {
      const newStatus = [...state.statuses];
      newStatus[0].title = action.payload;
      state.statuses = newStatus;
    },
    setSelectedStatus: (state, action: PayloadAction<IOrchestrationStatus>) => {
      state.selectedStatus = action.payload;
    },
    setOrchestrations: (state, action: PayloadAction<IOrchestrationRow[]>) => {
      state.orchestrations = action.payload;
    },
    setFilterInfo: (state, action: PayloadAction<IPostJobsInfo>) => {
      return {
        ...state,
        filterInfo: {
          jobStatuses: combineFilters(state.selectedStatus.filterId),
          activePage: state.paginatorInfo.page,
          itemsPerPage: state.paginatorInfo.pageSize,
          addJobInfo: true,
          jobsOwner: action.payload.jobsOwner ?? state.filterInfo?.jobsOwner,
          ...action.payload,
        },
      };
    },
    setFilterModel: (state, action: PayloadAction<any>) => {
      state.filterModel = action.payload;
    },
    composeFilterInfo: (state) => {
      state.filterInfo = {
        ...state.filterInfo,
        ...{
          jobStatuses: combineFilters(state.selectedStatus.filterId),
          activePage: state.paginatorInfo.page,
          itemsPerPage: state.paginatorInfo.pageSize,
        },
      };
    },
    resetSort: (state) => {
      return {
        ...state,
        filterInfo: {
          ...state.filterInfo,
          sortingField: undefined,
          sortingType: undefined,
          ...{
            jobStatuses: combineFilters(state.selectedStatus.filterId),
            activePage: 1,
            itemsPerPage: state.paginatorInfo.pageSize,
          },
        },
      };
    },
    setPaginatorInfo: (
      state,
      action: PayloadAction<IPaginatorStatusInterface>
    ) => {
      state.paginatorInfo = action.payload;
    },
    resetPaginatorInfo: (state) => {
      state.paginatorInfo = { ...initialState.paginatorInfo };
    },
    setSelectedOrchestrations: (
      state,
      action: PayloadAction<IOrchestrationRow[]>
    ) => {
      state.selectedOrchestrations = [...action.payload];
    },
    addSelectedOrchestration: (
      state,
      action: PayloadAction<IOrchestrationRow>
    ) => {
      if (
        !state.selectedOrchestrations?.find(
          (selected) => selected.id === action.payload.id
        )
      ) {
        state.selectedOrchestrations = [
          ...state.selectedOrchestrations,
          action.payload,
        ];
      }
    },
    removeSelectedOrchestration: (
      state,
      action: PayloadAction<IOrchestrationRow>
    ) => {
      state.selectedOrchestrations = state.selectedOrchestrations.filter(
        (selected) => selected.id !== action.payload.id
      );
    },
    setActiveFilters: (state, action: PayloadAction<IActiveFilter[]>) => {
      state.activeFilters = action.payload;
    },
    addActiveFilter: (state, action: PayloadAction<IActiveFilter>) => {
      const filterIndex = state.activeFilters.findIndex(
        (filter) => filter.id === action.payload.id
      );
      if (filterIndex !== -1) {
        state.activeFilters[filterIndex] = action.payload;
      } else {
        state.activeFilters.push(action.payload);
      }
    },
    removeActiveFilter: (state, action: PayloadAction<string>) => {
      state.activeFilters = state.activeFilters.filter(
        (filter) => filter.id !== action.payload
      );
    },
    addOrchestrationToSseQueue: (state, action: PayloadAction<IAddJob>) => {
      const newJob = mapJobToTableData(action.payload.job);
      if (state.filterInfo?.jobsOwner !== 'USER_JOBS') {
        return {
          ...state,
          sseOrchestrationsEvents: [
            {
              type: 'addOrchestration',
              data: newJob,
            },
            ...state.sseOrchestrationsEvents,
          ],
        };
      } else {
        if (newJob.user?.id === action.payload.userId) {
          return {
            ...state,
            sseOrchestrationsEvents: [
              {
                type: 'addOrchestration',
                data: newJob,
              },
              ...state.sseOrchestrationsEvents,
            ],
          };
        } else {
          return { ...state };
        }
      }
    },
    addOrchestrationRemoveLastOne: (
      state,
      action: PayloadAction<IOrchestrationRow>
    ) => {
      state.orchestrations.unshift(action.payload);
    },
    modifySseOrchestrationEvents: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        sseOrchestrationsEvents: action.payload,
      };
    },
    updateJobStatus: (
      state,
      action: PayloadAction<IUpdateOrchestrationStatus>
    ) => {
      return {
        ...state,
        sseOrchestrationsEvents: [
          ...state.sseOrchestrationsEvents,
          {
            type: 'updateStatus',
            data: action.payload,
          },
        ],
      };
    },
    updateStepStatus: (state, action: PayloadAction<IStepInformation>) => {
      return {
        ...state,
        sseOrchestrationsEvents: [
          ...state.sseOrchestrationsEvents,
          {
            type: 'updateStep',
            data: action.payload,
          },
        ],
      };
    },
    updateCounters: (state, action: PayloadAction<IStatusCounters>) => {
      const isFilterApplied =
        state.filterModel && Object.keys(state.filterModel).length;
      const isOnlyCounters = !action.payload.jobs;
      if (isFilterApplied && isOnlyCounters) {
        return;
      }
      const itemsToIterate = state.statuses
        ? state.statuses
        : ORCHESTRATION_FILTER_ITEMS;
      state.statuses = itemsToIterate.map((status) => {
        return {
          ...status,
          count: calculateCount(action.payload, status.id),
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllOrchestrations.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchAllOrchestrations.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(
      fetchAllOrchestrations.fulfilled,
      (state, action: PayloadAction<IOrchestrationsServerResponse>) => {
        state.selectedOrchestrations = [];
        if (!action.payload.jobs) {
          return {
            ...state,
            loading: false,
          };
        }
        state.orchestrations = setIsSelected(
          mapResponseJobsToTableData(action.payload.jobs),
          state.selectedOrchestrations
        );
        dashboardSlice.caseReducers.updateCounters(state, action as any);

        if (state.selectedStatus) {
          const selectedStatus = state.statuses.find(
            (status) => status.id === state.selectedStatus.id
          );
          if (selectedStatus) {
            state.selectedStatus = selectedStatus;
          }
        } else {
          state.selectedStatus = state.statuses[0];
        }

        state.loading = false;
      }
    );
    builder.addCase(postViewLog.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(postViewLog.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(postViewLog.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

    builder.addMatcher(
      orchestrationApi.endpoints.getUsersAll.matchFulfilled,
      (state, action: PayloadAction<IUserValue[]>) => {
        const uniqueUsers = action.payload.reduce(
          (acc: IJobStartedByUser[], user: IUserValue) => {
            if (!acc.find((u: IJobStartedByUser) => u.id === user.userId)) {
              acc.push({
                id: user.userId,
                name: getNameFromEmail(user.userEmail),
              });
            }
            return acc;
          },
          []
        );
        state.allUsers = uniqueUsers;
      }
    );
  },
});

export const selectFilterStatus = (state: RootState) =>
  state.dashboardContainer.filterInfo;

export const selectOrchestrations = (state: RootState) =>
  state.dashboardContainer.orchestrations;

export const selectPaginationInfo = (state: RootState) =>
  state.dashboardContainer.paginatorInfo;

export const selectFilterModel = (state: RootState) =>
  state.dashboardContainer.filterModel;

export const selectActiveFilter = (state: RootState) =>
  state.dashboardContainer.activeFilters;

export const selectSelectedStatus = (state: RootState) =>
  state.dashboardContainer.selectedStatus;

export const selectStatuses = (state: RootState) =>
  state.dashboardContainer.statuses;
export const selectSelectedOrchestrations = (state: RootState) =>
  state.dashboardContainer.selectedOrchestrations;

export type SelectFilterInfoType = ReturnType<typeof selectFilterStatus>;
export type SelectOrchestrationsType = ReturnType<typeof selectOrchestrations>;
export type SelectPaginationInfoType = ReturnType<typeof selectPaginationInfo>;
export type SelectFilterModelType = ReturnType<typeof selectFilterModel>;
export type SelectActiveFilterType = ReturnType<typeof selectActiveFilter>;
export type SelectSelectedStatusType = ReturnType<typeof selectSelectedStatus>;
export type SelectStatusesType = ReturnType<typeof selectStatuses>;

export const {
  setStatuses,
  setLabelForFirstStatus,
  setSelectedStatus,
  setOrchestrations,
  setSelectedOrchestrations,
  setFilterInfo,
  setFilterModel,
  addSelectedOrchestration,
  removeSelectedOrchestration,
  addActiveFilter,
  removeActiveFilter,
  setActiveFilters,
  setPaginatorInfo,
  resetPaginatorInfo,
  composeFilterInfo,
  addOrchestrationToSseQueue,
  updateJobStatus,
  updateCounters,
  updateStepStatus,
  resetSort,
  modifySseOrchestrationEvents,
  addOrchestrationRemoveLastOne,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
