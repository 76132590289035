import React from 'react';

class CsbErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error: any) {
    this.setState({ error: `There has been an error in this component` });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <div role="alert">{error}</div>;
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default CsbErrorBoundary;
