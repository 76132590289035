import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IServerWorkflow } from 'interfaces/workflow.interface';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred';

const BASE_URL = '/csb/workflows';

export const fetchWorkflows = createAsyncThunk(
  'runOrchestrations/fetchWorkflows',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IServerWorkflow[]>(`${BASE_URL}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);

export const fetchWorkflowsNames = createAsyncThunk(
  'runOrchestrations/fetchWorkflowsNames',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<string[]>(`${BASE_URL}/names/all`, {
        withCredentials: true,
      });
      return response.data ?? [];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);
