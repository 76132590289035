import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ICreateProduct } from 'interfaces/modals/create-product.interface';
import {
  IOrchestrationsByModuleServer,
  IOrchestrationsByModuleServerRequest,
} from 'interfaces/modules/module.interface';
import { IDeleteWorkflow } from 'interfaces/runOrchestration/workflow-row';
import { IServerWorkflow } from 'interfaces/workflow.interface';

export const workflowsApi = createApi({
  reducerPath: 'workflowsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/csb',
    prepareHeaders: (headers, { endpoint }) => {
      if (endpoint === 'importWorkflow') {
        headers.set('Content-Type', 'application/xml');
      } else {
        headers.set('Content-Type', 'application/json');
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWorkflowExport: builder.query<any, string>({
      query: (name: string) => {
        return {
          url: `workflows/export/${name}`,
          credentials: 'include',
          responseHandler: (response: Response) => response?.text(),
        };
      },
      keepUnusedDataFor: 0,
    }),
    getWorkflowsByModule: builder.query<
      IOrchestrationsByModuleServer,
      IOrchestrationsByModuleServerRequest
    >({
      query: ({
        slave,
        moduleTextId,
        moduleVersion,
      }: IOrchestrationsByModuleServerRequest) => {
        return {
          url: `workflows/${slave}/${moduleTextId}/${moduleVersion}`,
          credentials: 'include',
        };
      },
      keepUnusedDataFor: 1,
    }),
    getWorkflowById: builder.query<IServerWorkflow, { name: string }>({
      query: ({ name }: any) => {
        return {
          url: `workflows/${name}`,
          credentials: 'include',
        };
      },
      keepUnusedDataFor: 1,
    }),
    // mutations
    deleteWorkflow: builder.mutation<any, IDeleteWorkflow>({
      query: ({ id, productId }: IDeleteWorkflow) => {
        return {
          url: `${
            productId
              ? `/workflows/product/${id}/${productId}`
              : `workflows/${id}`
          }`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
    createProduct: builder.mutation<ICreateProduct, ICreateProduct>({
      query: (data: ICreateProduct) => {
        return {
          url: `/workflows/product`,
          method: 'POST',
          body: { ...data },
          credentials: 'include',
        };
      },
    }),
    toggleDeactivate: builder.mutation<any, number>({
      query: (id: number) => {
        return {
          url: `/workflows/toggleDeactivate/${id}`,
          method: 'POST',
          credentials: 'include',
        };
      },
    }),
    importWorkflow: builder.mutation<any, File>({
      query: (xmlContent: File) => {
        return {
          url: `/workflows/import`,
          method: 'PUT',
          body: xmlContent,
          headers: {
            'Content-Type': 'application/xml',
          },
          credentials: 'include',
          responseHandler: (response: Response) => response.text(),
        };
      },
    }),
  }),
});

export const {
  useDeleteWorkflowMutation,
  useCreateProductMutation,
  useLazyGetWorkflowExportQuery,
  useToggleDeactivateMutation,
  useImportWorkflowMutation,
  useGetWorkflowsByModuleQuery,
  useLazyGetWorkflowByIdQuery,
} = workflowsApi;
